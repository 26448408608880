import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { LimitRequest } from 'types';
import { solanaApiQuery } from 'store/query';
import { useSolanaWalletAddress } from 'hooks';
import { limitResponseArrayByRequestParam } from 'utils/query';

import { SolanaDelegationData, SolanaDelegationsResponse } from './types';

export const solanaDelegationsTagTypes = ['solana-delegations'];

export const solanaDelegationsApi = createApi({
  baseQuery: solanaApiQuery,
  tagTypes: solanaDelegationsTagTypes,
  reducerPath: 'solanaDelegationsApi',
  endpoints: (build) => ({
    getSolanaDelegations: build.query<SolanaDelegationsResponse, string>({
      providesTags: solanaDelegationsTagTypes,
      query: (walletAddress) => ({
        method: 'get',
        url: URL.DELEGATIONS.replace('{walletAddress}', walletAddress),
      }),
    }),
  }),
});

export const { useGetSolanaDelegationsQuery } = solanaDelegationsApi;

export const useSolanaDelegations = (
  requestParams:
    | void
    | ({
        excludeAddresses?: string[];
      } & LimitRequest)
) => {
  const address = useSolanaWalletAddress();

  const limit = requestParams?.limit;
  const excludeAddresses = requestParams?.excludeAddresses;

  return useGetSolanaDelegationsQuery(address, {
    skip: !address,
    selectFromResult: (result) => ({
      ...result,
      data: result.data
        ? {
            ...result.data,
            delegationDataDetails: limitResponseArrayByRequestParam(
              result.data.delegationDataDetails,
              {
                limit,
              }
            ).filter(({ validatorAddress }) =>
              excludeAddresses
                ? !excludeAddresses.includes(validatorAddress)
                : true
            ),
          }
        : undefined,
    }),
  });
};

export type { SolanaDelegationData, SolanaDelegationsResponse };
