import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { urlPattern, passwordPattern } from './regex';

export const phoneNumber = Yup.string()
  .nullable()
  .test('Phone number', 'Invalid phone number', function (value) {
    return value && value.length > 0 ? isValidPhoneNumber(value) : true;
  });

export const password = Yup.string()
  .trim()
  .min(8)
  .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
  .matches(/[a-z]/, 'Password must contain at least one lowercase character')
  .matches(
    /[^A-Za-z0-9]/,
    'Password must contain at least one symbol character'
  )
  .required();

export const passwordConfirmation = (refName: string) =>
  Yup.string().oneOf([Yup.ref(refName)], 'Passwords must match');

export const email = Yup.string()
  .email('Email is not valid')
  .required('Email is required');

export const confirmPassword = password.test(
  'passwordRepeat',
  'Passwords must match',
  function (value) {
    return this.parent.password === value;
  }
);

export const advancedPassword = Yup.string()
  .trim()
  .matches(
    passwordPattern,
    'Password should contain minimum 8 characters with one numeric, one lowercase and one uppercase character'
  )
  .required();

export const url = Yup.string().matches(urlPattern, 'Enter a valid url');

export const passwordValidationRules: Record<
  string,
  (value: string) => boolean
> = {
  '8 characters': (value) => value.length >= 8,
  '1 number': (value) => /^(?=.*[0-9])/.test(value),
  '1 lowercase': (value) => /^(?=.*[a-z])/.test(value),
  '1 uppercase': (value) => /^(?=.*[A-Z])/.test(value),
};

export { isValidPhoneNumber };

export const validateSolanaAddress = (address: string) => {
  const regex = '^[1-9A-HJ-NP-Za-km-z]{32,44}$';

  return new RegExp(regex).test(address);
};
