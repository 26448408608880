import { FlexProps } from 'polli-commons-fe/components/styles';

import { ValidatorInfo } from 'components/validator-info';
import { CosmosValidatorImage } from 'components/cosmos-validator-image';

export const CosmosValidatorInfo = ({
  name,
  address,
  ...props
}: {
  name?: string;
  address: string;
} & FlexProps) => (
  <ValidatorInfo
    name={name}
    icon={<CosmosValidatorImage rounded address={address} />}
    {...props}
  />
);
