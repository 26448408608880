import { determineCosmosChainFromAddress } from 'polli-commons-fe/utils/helpers';

import { useGetCompoundingConfigQuery } from 'store/api/compounding-config';

export const useCompoundGranteeAddress = (walletAddress?: string | null) => {
  const chainType = walletAddress
    ? determineCosmosChainFromAddress(walletAddress)
    : undefined;

  return useGetCompoundingConfigQuery(undefined, {
    skip: !chainType,
    selectFromResult: (response) => ({
      ...response,
      data: response.data?.signerWalletAddresses[chainType!],
    }),
  });
};
