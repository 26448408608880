import { axiosBaseQuery } from 'polli-commons-fe/store/query';

import { solanaApiInstance, compoundingApiInstance } from 'api/instance';

export const compoundingApiQuery = axiosBaseQuery({
  instance: compoundingApiInstance,
});

export const solanaApiQuery = axiosBaseQuery({
  instance: solanaApiInstance,
});
