import { LimitRequest } from 'types';

const waitFor = (millSeconds: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, millSeconds);
  });
};

export const retryPromiseWithDelay = async <T>(
  promise: (retriesLeft: number) => Promise<T>,
  nthTry: number,
  delayTime: number
): Promise<T> => {
  try {
    return await promise(nthTry);
  } catch (e) {
    if (nthTry === 1) {
      return Promise.reject(e);
    }
    await waitFor(delayTime);
    return retryPromiseWithDelay(promise, nthTry - 1, delayTime);
  }
};

export const limitResponseArrayByRequestParam = <T>(
  response: T[],
  arg: LimitRequest
): T[] => {
  if (arg.limit) {
    return response.slice(0, arg.limit);
  }

  return response;
};
