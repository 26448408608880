import { useMemo, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import { CosmosChainType } from 'polli-commons-fe/types';
import { determineCosmosChainFromAddress } from 'polli-commons-fe/utils/helpers';

import { WalletProvider } from 'types';
import { useAppSelector } from 'store';
import { useGetQuery } from 'store/api/wallets';
import { SOLANA_ENABLED } from 'config/constants';
import { useGetSolanaWallets } from 'store/api/solana/wallets';
import { useGetCosmosWalletsQuery } from 'store/api/cosmos-wallets';
import { selectConnectedCosmosProviderName } from 'store/slices/connected-cosmos-chains';

import { useWalletProviders } from './use-wallet-providers';

export const useConnectedUserWallets = (): (
  | {
      address: string;
      chainType: 'SOLANA';
      provider?: 'phantom';
    }
  | {
      address: string;
      provider?: 'keplr';
      chainType: CosmosChainType;
    }
  | {
      address: string;
      chainType: 'ETHEREUM';
      provider?: Exclude<WalletProvider, 'keplr'>;
    }
)[] => {
  const { trezor, cosmos, phantom, metamask, walletConnect } =
    useWalletProviders();
  const { data: ethWallets } = useGetQuery();
  const { data: cosmosWallets } = useGetCosmosWalletsQuery();
  const { data: solanaWallets } = useGetSolanaWallets();

  const cosmosWalletProviderName = useAppSelector(
    selectConnectedCosmosProviderName
  );

  const hasSavedEthWallet = useCallback(
    (address: string) =>
      ethWallets?.some(({ wallet }) => wallet.address === address),
    [ethWallets]
  );

  const hasSavedSolanaWallet = useCallback(
    (address: string) =>
      solanaWallets?.some(({ wallet }) => wallet.address === address),
    [solanaWallets]
  );

  return useMemo(() => {
    const result = [];

    if (isMobile) {
      if (ethWallets) {
        result.push(
          ...ethWallets
            .filter(({ wallet }) => !wallet.isReadOnly)
            .map((data) => ({
              address: data.wallet.address,
              chainType: data.wallet.chainType,
            }))
        );
      }

      if (cosmosWallets) {
        result.push(
          ...cosmosWallets
            .filter(({ wallet }) => !wallet.isReadOnly)
            .map((data) => ({
              address: data.wallet.address,
              chainType: data.wallet.chainType,
            }))
        );
      }
    } else {
      if (metamask.address && hasSavedEthWallet(metamask.address)) {
        result.push({
          address: metamask.address,
          provider: 'metamask' as const,
          chainType: 'ETHEREUM' as const,
        });
      }

      Object.values(cosmos.chains).forEach((chain) => {
        if (
          chain.address &&
          cosmosWallets?.some(({ wallet }) => wallet.address === wallet.address)
        ) {
          result.push({
            address: chain.address,
            provider: cosmosWalletProviderName,
            chainType: determineCosmosChainFromAddress(chain.address),
          });
        }
      });

      if (walletConnect.address && hasSavedEthWallet(walletConnect.address)) {
        result.push({
          address: walletConnect.address,
          chainType: 'ETHEREUM' as const,
          provider: 'walletConnect' as const,
        });
      }

      if (trezor.ethAddress && hasSavedEthWallet(trezor.ethAddress)) {
        result.push({
          address: trezor.ethAddress,
          provider: 'trezor' as const,
          chainType: 'ETHEREUM' as const,
        });
      }

      if (
        SOLANA_ENABLED &&
        phantom.publicKey &&
        hasSavedSolanaWallet(phantom.publicKey.toBase58())
      ) {
        result.push({
          chainType: 'SOLANA' as const,
          provider: 'phantom' as const,
          address: phantom.publicKey.toBase58(),
        });
      }
    }

    return result;
  }, [
    cosmos,
    ethWallets,
    phantom.publicKey,
    cosmosWallets,
    metamask.address,
    hasSavedEthWallet,
    hasSavedSolanaWallet,
    trezor.ethAddress,
    walletConnect.address,
    cosmosWalletProviderName,
  ]);
};
