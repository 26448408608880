import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from 'polli-commons-fe/utils/error';
import { ChainId, CosmosWalletProvider } from 'polli-commons-fe/types';
import { getCosmosWindowProvider } from 'polli-commons-fe/utils/cosmos';

import { uint8ArrayToBase64 } from 'utils/helpers';
import { ENABLED_CHAINS_TO_CONNECT } from 'config/constants';
import { saveAuthTypeToLocalStorage } from 'store/slices/auth/helpers';
import { connectCosmosChains } from 'store/slices/connected-cosmos-chains';
import {
  clearWalletSignInTokens,
  generateAndSaveWalletSignInTokens,
} from 'utils/wallet-sign-in';

import { AuthUser, AuthType, AuthEmail } from './types';

export interface Auth {
  isLoggedIn: boolean;
  authUser: AuthUser | null;
  redirectUrl?: string | null;
}

export const authInitialState: Auth = {
  authUser: null,
  isLoggedIn: false,
};

export const walletSignIn = createAsyncThunk(
  'auth/walletSignIn',
  async (
    {
      redirectUrl,
      providerName,
    }: { redirectUrl?: string; providerName: CosmosWalletProvider },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const windowProvider = getCosmosWindowProvider(providerName);

      const chainId = ChainId.COSMOS;
      await windowProvider?.enable(chainId);
      const signerProvider =
        await windowProvider?.getOfflineSignerAuto(chainId);
      const accounts = await signerProvider?.getAccounts();
      const currentAccount = accounts?.[0];
      const walletAddress = currentAccount?.address ?? null;

      const publicKey = currentAccount?.pubkey;
      const publicKeyBase64 = publicKey ? uint8ArrayToBase64(publicKey) : null;

      if (walletAddress) {
        const authUserSub = await generateAndSaveWalletSignInTokens({
          walletAddress,
          publicKey: publicKeyBase64,
          onMessageSign: async (messageToSign: string) => {
            const signResponse = await windowProvider?.signArbitrary(
              chainId,
              walletAddress,
              messageToSign
            );

            return signResponse?.signature;
          },
        });

        await dispatch(connectCosmosChains(providerName));

        return {
          authUserSub,
          redirectUrl,
          isLoggedIn:
            ENABLED_CHAINS_TO_CONNECT.includes('COSMOS') &&
            ENABLED_CHAINS_TO_CONNECT.length === 1,
        };
      }
    } catch (e) {
      if ((e as any).status === 404) {
        return rejectWithValue(
          "We couldn't fetch your wallet details because you haven't made any transactions yet. To proceed, please ensure your account has sufficient tokens"
        );
      }

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  extraReducers: (builder) => {
    builder.addCase(walletSignIn.rejected, () => {
      clearWalletSignInTokens();
      return authInitialState;
    });
    builder.addCase(walletSignIn.fulfilled, (state, { payload }) => {
      state.authUser = {
        sub: payload?.authUserSub,
      };
      state.isLoggedIn = payload?.isLoggedIn ?? false;
      state.redirectUrl = payload?.redirectUrl;
      saveAuthTypeToLocalStorage('wallet');
    });
  },
  reducers: {
    loginUser: (state) => {
      state.isLoggedIn = true;
    },
    clearAuth: () => {
      clearWalletSignInTokens();
      return authInitialState;
    },
    setAuthUser: (state, { payload }) => {
      state.authUser = {
        ...state.authUser,
        ...payload,
      };
    },
    setAuthRedirectUrl: (
      state,
      { payload }: PayloadAction<Auth['redirectUrl']>
    ) => {
      state.redirectUrl = payload;
    },
  },
});

export const {
  actions: { clearAuth, loginUser, setAuthUser, setAuthRedirectUrl },
} = authSlice;

export * from './selectors';

export type { AuthUser, AuthType, AuthEmail };
