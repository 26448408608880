import styled, { css } from 'styled-components';

import { Icon as CoreIcon } from 'polli-commons-fe/components';

import { Icon } from 'components/icons';

const whiteDropShadow = css`
  filter: drop-shadow(0px 0px 10px #ffffff);
`;

const size = css`
  width: 80px;
  height: 80px;
`;

export const CosmosIcon = styled(Icon.CosmosLarge)`
  ${whiteDropShadow};
`;

export const EthereumIcon = styled(Icon.EthereumLarge)`
  filter: drop-shadow(0px 0px 10px #627eea);
`;

export const SecretIcon = styled(CoreIcon.Secret)`
  ${size};
  ${whiteDropShadow};
`;

export const SolanaIcon = styled(CoreIcon.Solana)`
  ${size};
  ${whiteDropShadow};
`;
