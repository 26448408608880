import axios from 'axios';

import { determineCosmosChainFromAddress } from 'polli-commons-fe/utils/helpers';
import {
  ChainId,
  CosmosChainType,
  CosmosWalletProvider,
} from 'polli-commons-fe/types';

import { ChainDenom } from 'types';
import { ChainRestUrl } from 'types/data';

export const getCosmosWindowProvider = (provider: CosmosWalletProvider) => {
  if (provider === 'cosmostation') {
    return window?.cosmostation?.providers?.keplr;
  }

  if (provider === 'keplr' && (window?.keplr as any)?.ethereum.isKeplr) {
    return window?.keplr;
  }

  if (provider === 'leap' && (window?.leap as any)?.ethereum.isLeap) {
    return window?.leap;
  }

  return null;
};

export const getCosmosWalletBalance = async (address: string) => {
  const chainType = determineCosmosChainFromAddress(address);
  const restUrl = ChainRestUrl[chainType];

  const response = await axios.get<{
    balances: { denom: string; amount: string }[];
  }>(`${restUrl}/cosmos/bank/v1beta1/balances/${address}`);

  return response.data.balances.find(
    ({ denom }) => denom === ChainDenom[chainType]
  );
};

export const getCosmosWalletAddress = async ({
  chainType,
  providerName,
}: {
  chainType: CosmosChainType;
  providerName: CosmosWalletProvider;
}) => {
  const windowProvider = getCosmosWindowProvider(providerName);

  const chainId = ChainId[chainType];
  await windowProvider?.enable(chainId);
  const signerProvider = await windowProvider?.getOfflineSignerAuto(chainId);
  const accounts = await signerProvider?.getAccounts();
  const walletAddress = accounts?.[0].address ?? null;

  return walletAddress;
};
