import { determineCosmosChainFromAddress } from 'polli-commons-fe/utils/helpers';
import {
  BaseValidatorImage,
  BaseValidatorImageProps,
} from 'polli-commons-fe/components/base-validator-image';

import { useGetCosmosValidatorsQuery } from 'store/api/cosmos-validators';

export const CosmosValidatorImage = ({
  address,
  ...props
}: {
  address?: string;
} & BaseValidatorImageProps) => {
  const chainType = address
    ? determineCosmosChainFromAddress(address)
    : undefined;

  const { data: validatorsData } = useGetCosmosValidatorsQuery(
    {
      chainType: chainType!,
    },
    {
      skip: !chainType,
    }
  );

  const src = validatorsData?.data.find(
    (val) => val.address === address
  )?.imageUrl;

  return <BaseValidatorImage {...props} src={src} />;
};
