import { createApi } from '@reduxjs/toolkit/query/react';

import { AmountResponse } from 'polli-commons-fe/types';
import { cosmosApiQuery } from 'polli-commons-fe/store/query';

import { URL } from 'api/constants';

export const cosmosWalletBalanceTagTypes = ['cosmos-wallet-balance'];

export const cosmosWalletBalanceApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'cosmosWalletBalanceApi',
  tagTypes: cosmosWalletBalanceTagTypes,
  endpoints: (build) => ({
    getCosmosWalletBalance: build.query<AmountResponse, string>({
      providesTags: cosmosWalletBalanceTagTypes,
      query: (walletAddress) => ({
        method: 'get',
        url: URL.WALLET_BALANCE.replace('{walletAddress}', walletAddress),
      }),
    }),
  }),
});

export const { useGetCosmosWalletBalanceQuery } = cosmosWalletBalanceApi;
