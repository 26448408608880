import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'polli-commons-fe/store/query';
import { replaceAllWithMap } from 'polli-commons-fe/utils/string';
import {
  getSortParams,
  getPageParams,
  addParamsToUrl,
} from 'polli-commons-fe/utils/query';

import { URL } from 'api/constants';

import {
  CosmosValidatorData,
  GetCosmosValidatorsRequest,
  GetCosmosValidatorsResponse,
  GetCosmosBalanceHistoryRequest,
  GetCosmosBalanceHistoryResponse,
} from './types';

export const cosmosValidatorsTagTypes = ['cosmos-validators'];

export const cosmosValidatorsApi = createApi({
  baseQuery: cosmosApiQuery,
  tagTypes: cosmosValidatorsTagTypes,
  reducerPath: 'cosmosValidatorsApi',
  endpoints: (build) => ({
    getCosmosValidators: build.query<
      GetCosmosValidatorsResponse,
      GetCosmosValidatorsRequest
    >({
      providesTags: cosmosValidatorsTagTypes,
      query: ({ sort, page = 0, size = 1000, preferencesSort, ...params }) => {
        return {
          method: 'get',
          url: addParamsToUrl(URL.VALIDATORS, {
            size,
            ...params,
            ...getPageParams(page),
            ...getSortParams({
              ...sort,
              ...(preferencesSort ? JSON.parse(preferencesSort) : {}),
            }),
          }),
        };
      },
    }),

    getCosmosBalanceHistory: build.query<
      GetCosmosBalanceHistoryResponse,
      GetCosmosBalanceHistoryRequest
    >({
      query: ({ balanceRange, walletAddress, validatorAddress }) => {
        const urlWithAddresses = replaceAllWithMap(
          URL.COSMOS_VALIDATORS_BALANCE_HISTORY,
          {
            '{walletAddress}': walletAddress,
            '{validatorAddress}': validatorAddress,
          }
        );

        return {
          method: 'get',
          url: addParamsToUrl(urlWithAddresses, {
            balanceRange,
          }),
        };
      },
    }),
  }),
});

export const { useGetCosmosValidatorsQuery, useGetCosmosBalanceHistoryQuery } =
  cosmosValidatorsApi;

export type {
  CosmosValidatorData,
  GetCosmosValidatorsRequest,
  GetCosmosValidatorsResponse,
  GetCosmosBalanceHistoryRequest,
  GetCosmosBalanceHistoryResponse,
};
