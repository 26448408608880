import { CosmosChainType } from 'polli-commons-fe/types';

export const COMPOUND_ENABLED: boolean = import.meta.env
  .VITE_REACT_APP_COMPOUNDING_ENABLED
  ? JSON.parse(import.meta.env.VITE_REACT_APP_COMPOUNDING_ENABLED)
  : false;

export const SECRET_ENABLED: boolean = import.meta.env
  .VITE_REACT_APP_SECRET_ENABLED
  ? JSON.parse(import.meta.env.VITE_REACT_APP_SECRET_ENABLED)
  : false;

export const ENABLED_COSMOS_NETWORK_CHAIN_TYPES = [
  'COSMOS',
  SECRET_ENABLED && 'SECRET',
  'LAVA',
].filter(Boolean) as CosmosChainType[];

export const DISABLED_COMPOUND_CHAIN_TYPES: CosmosChainType[] = [].filter(
  Boolean
) as CosmosChainType[];

export const READONLY_WALLETS_ENABLED: boolean = import.meta.env
  .VITE_REACT_APP_READ_ONLY_WALLETS_ENABLED
  ? JSON.parse(import.meta.env.VITE_REACT_APP_READ_ONLY_WALLETS_ENABLED)
  : false;

export const SOLANA_ENABLED = import.meta.env.VITE_REACT_APP_SOLANA_ENABLED
  ? JSON.parse(import.meta.env.VITE_REACT_APP_SOLANA_ENABLED)
  : false;

export const ENABLED_CHAINS_TO_CONNECT = [
  'COSMOS',
  SOLANA_ENABLED ? 'SOLANA' : null,
].filter(Boolean);
