import { useCallback } from 'react';
import { fetchMFAPreference, fetchUserAttributes } from 'aws-amplify/auth';

import { useAppDispatch } from 'store';
import { loginUser, setAuthUser } from 'store/slices/auth';
import { saveAuthTypeToLocalStorage } from 'store/slices/auth/helpers';

export const useUpdateUser = () => {
  const dispatch = useAppDispatch();

  const updateUser = useCallback(async () => {
    try {
      const { sub, email, email_verified } = await fetchUserAttributes();
      const { preferred } = await fetchMFAPreference();

      if (sub) {
        saveAuthTypeToLocalStorage('email');
      }

      dispatch(
        setAuthUser({
          sub,
          email: email,
          preferredMFA: preferred,
          email_verified: email_verified,
        })
      );
      dispatch(loginUser());

      return {
        preferred,
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }, [dispatch]);

  return updateUser;
};
