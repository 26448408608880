import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Flex, Button } from 'polli-commons-fe/components';

import { LottieLoader } from 'components';
import bgStars from 'assets/deposit-confirmation/loading-stars.svg';

export const LoadingContainer = styled(motion.div)`
  background: ${({ theme }) => theme.colors.modalBackground};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const HeadingContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 377px;
  margin-top: 67px;
  width: 80%;
  background: url(${bgStars}) no-repeat center / cover;
  position: relative;
`;

export const HeadingBackgroundGradient = styled.div`
  width: 700px;
  height: 200px;
  position: absolute;
  z-index: -1;
  filter: blur(132px);
  background: linear-gradient(
    238deg,
    rgba(254, 202, 68, 0.5) -14.75%,
    rgba(141, 201, 42, 0.5) 11.26%,
    rgba(42, 201, 125, 0.5) 38.45%,
    rgba(9, 174, 197, 0.5) 67.98%,
    rgba(9, 120, 197, 0.5) 95.59%
  );
`;

export const Heading = styled.header`
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const backgroundEllipsis = css`
  position: absolute;
  width: 536px;
  height: 486px;
  border-radius: 536px;
  background: linear-gradient(
    238deg,
    rgba(254, 202, 68, 0.1) -14.75%,
    rgba(141, 201, 42, 0.1) 11.26%,
    rgba(42, 201, 125, 0.1) 38.45%,
    rgba(9, 174, 197, 0.1) 67.98%,
    rgba(9, 120, 197, 0.1) 95.59%
  );
  filter: blur(132px);
`;

export const LeftBackgroundEllipsis = styled.div`
  ${backgroundEllipsis};
  left: -25%;
`;

export const RightBackgroundEllipsis = styled.div`
  ${backgroundEllipsis};
  right: -20%;
  bottom: 0;
`;

export const LottieAnimation = styled(LottieLoader)`
  top: 25%;
  width: 100%;
  position: absolute;
  z-index: -1;
` as typeof LottieLoader;

export const CloseButton = styled(Button)`
  position: absolute;
  right: 24px;
  top: 24px;
`;
