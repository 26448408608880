import { useMemo } from 'react';

import { determineCosmosChainFromAddress } from 'polli-commons-fe/utils/helpers';

import { isCosmosChainType } from 'utils/helpers';
import {
  COMPOUND_ENABLED,
  DISABLED_COMPOUND_CHAIN_TYPES,
} from 'config/constants';

import { useConnectedWallets } from '../use-connected-wallets';

import { useCompoundGranteeAddress } from './use-compound-grantee-address';

export const useCompoundEnabled = (
  walletAddress: string | null | undefined
) => {
  const chainType = walletAddress
    ? determineCosmosChainFromAddress(walletAddress)
    : null;
  const connectedWallets = useConnectedWallets(chainType);

  const isUserWallet = !connectedWallets.find(
    ({ wallet }) => wallet.address === walletAddress
  )?.wallet.isReadOnly;

  const { data: granteeAddress } = useCompoundGranteeAddress(walletAddress);

  const currentChainTypeIsCosmosRelated =
    chainType && isCosmosChainType(chainType);
  const compoundDisabledForChainType =
    chainType &&
    currentChainTypeIsCosmosRelated &&
    DISABLED_COMPOUND_CHAIN_TYPES.includes(chainType);

  return useMemo(
    () =>
      Boolean(
        COMPOUND_ENABLED &&
          isUserWallet &&
          currentChainTypeIsCosmosRelated &&
          !compoundDisabledForChainType &&
          granteeAddress
      ),
    [
      isUserWallet,
      granteeAddress,
      compoundDisabledForChainType,
      currentChainTypeIsCosmosRelated,
    ]
  );
};
