import { AmountResponse } from 'polli-commons-fe/types';

import { DelegableChainType, TransactionStatus } from 'types';

export interface DelegatedItemInfo {
  amount: string;
}

export type DelegationProcessType =
  | 'toProvider'
  | 'toValidator'
  | 'onlyOptimizer';

export const allSteps = [
  'preferences',
  'summary',
  'optimizer',
  'choose-provider',
  'optimizer-current-validators',
  'optimizer-confirmation',
] as const;

export const toValidatorStepsOrder: Step[] = [
  'preferences',
  'summary',
  'optimizer',
];

export const onlyOptimizerStepsOrder: Step[] = [
  'preferences',
  'optimizer-current-validators',
  'optimizer-confirmation',
];

export const toProviderStepsOrder: Step[] = ['choose-provider'];

export type Step = (typeof allSteps)[number];

export interface DelegationProcessState {
  step: Step;
  retries: number;
  showLoader: boolean;
  retriesLimit: number;
  optimizerEnabled: boolean;
  type: DelegationProcessType;
  selectedPreferences: string[];
  totalTokensToDelegate: string;
  delegationInProgress: boolean;
  selectedWalletAddress: string | null;
  status: TransactionStatus | null;
  currencyLabel: string | null | undefined;
  chainType: DelegableChainType | undefined;
  delegationData: {
    gasUsed?: AmountResponse;
    items: Record<string, DelegatedItemInfo>;
  };
}

export const stepsByTypeOrder = {
  toProvider: toProviderStepsOrder,
  toValidator: toValidatorStepsOrder,
  onlyOptimizer: onlyOptimizerStepsOrder,
};
