import styled, { css } from 'styled-components';

import { getThemeChainBasedColor } from 'utils/helpers';

import { Flex } from '../../styles';
import { TableRowProps } from '../types';
import { handleTableRowTemplate } from '../../../styles/mixins';

export const TableRowContainer = styled.div<
  {
    $gap: number;
    $padding: string;
    $template: string;
  } & Pick<TableRowProps, 'disabled' | 'chainType'>
>`
  display: grid;
  border-radius: 28px;
  align-items: center;

  ${handleTableRowTemplate};

  ${({ $gap, theme, onClick, $padding, disabled, chainType }) => {
    const bgColors = getThemeChainBasedColor({
      chainType,
      colors: theme.config.tableRow.background,
    });

    return css`
      grid-gap: ${$gap}px;
      padding: ${$padding};

      ${disabled &&
      css`
        opacity: 0.3;
        pointer-events: none;
      `}

      ${!disabled &&
      onClick &&
      css`
        cursor: pointer;

        &:hover {
          background: ${bgColors.hover};
        }

        &:active {
          background: ${bgColors.active};
        }
      `}

      background: ${bgColors.main};
      box-shadow: ${theme.config.tableRow.boxShadow};
      backdrop-filter: ${theme.config.tableRow.backdropFilter};
      -webkit-backdrop-filter: ${theme.config.tableRow.backdropFilter};
    `;
  }}
`;

export const ItemContainer = styled(Flex)`
  min-width: 0;
  flex-direction: column;
`;
