import { createApi } from '@reduxjs/toolkit/query/react';

import { ethereumApiQuery } from 'polli-commons-fe/store/query';

import { URL } from 'api/constants';

import {
  ChainSummariseData,
  WalletBalanceHistorySummariseRequest,
  WalletsSummariseDelegationDataRequest,
  WalletBalanceHistorySummariseResponse,
  WalletsSummarizeIncomeHistoryResponse,
  WalletsSummariseIncomeHistoryDataRequest,
} from './types';

export const walletSummariseTagTypes = ['wallets-summarise'];

export const walletsSummariseApi = createApi({
  baseQuery: ethereumApiQuery,
  tagTypes: walletSummariseTagTypes,
  reducerPath: 'walletsSummariseApi',
  endpoints: (build) => ({
    getWalletSummariseDelegationData: build.query<
      ChainSummariseData,
      WalletsSummariseDelegationDataRequest
    >({
      query: (data) => ({
        data,
        method: 'post',
        url: URL.WALLETS_SUMMARISE_DELEGATIONS,
      }),
    }),
    getWalletSummariseBalanceHistoryData: build.query<
      WalletBalanceHistorySummariseResponse[],
      WalletBalanceHistorySummariseRequest
    >({
      query: (data) => ({
        data,
        method: 'post',
        url: URL.WALLETS_SUMMARISE_BALANCE_HISTORY,
      }),
    }),
    getWalletSummariseIncomeHistoryData: build.query<
      WalletsSummarizeIncomeHistoryResponse[],
      WalletsSummariseIncomeHistoryDataRequest
    >({
      query: (data) => ({
        data,
        method: 'post',
        url: URL.WALLETS_SUMMARISE_INCOME_HISTORY,
      }),
    }),
  }),
});

export const {
  useGetWalletSummariseDelegationDataQuery,
  useGetWalletSummariseIncomeHistoryDataQuery,
  useGetWalletSummariseBalanceHistoryDataQuery,
} = walletsSummariseApi;

export const { getWalletSummariseDelegationData } =
  walletsSummariseApi.endpoints;
