import { Link as RouterLink } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';

import { ChainType } from 'polli-commons-fe/types';

import { Icon } from '../icons';
import { StyleTypeFieldName } from '../../config/themes/types';
import { gradientBorder, getAccentGradientText } from '../../styles/mixins';

import { ButtonProps, ButtonStyleType } from './types';

export interface ButtonStyleProps {
  $chainType?: ChainType;
  $isInteractive?: boolean;
  $bold?: ButtonProps['bold'];
  $grow?: ButtonProps['grow'];
  $styleType: ButtonStyleType;
  $width?: ButtonProps['width'];
  $iconColorParam?: ButtonProps['iconColorParam'];
  $gradientBorder?: ButtonProps['gradientBorder'];
  $nativeIconColor?: ButtonProps['nativeIconColor'];
}

export const Text = styled.div``;

const getTextColor = (
  theme: DefaultTheme,
  styleType: ButtonStyleType,
  type: StyleTypeFieldName,
  chainType?: ChainType
) => {
  const buttonConfig = theme.config.button[styleType];

  if (styleType === 'gradient' && chainType) {
    return getAccentGradientText(
      theme.config.gradientText[
        chainType as keyof typeof theme.config.gradientText
      ]
    );
  }

  const colorConfig = 'color' in buttonConfig && buttonConfig.color;

  if (typeof colorConfig === 'object') {
    const color = colorConfig[type];

    if (color?.startsWith('linear-gradient')) {
      return getAccentGradientText(color);
    }

    return css`
      background: revert;
      -webkit-background-clip: revert;
      -webkit-text-fill-color: revert;
      color: ${color} !important;
    `;
  }

  return css`
    background: revert;
    -webkit-background-clip: revert;
    -webkit-text-fill-color: revert;
    color: ${colorConfig} !important;
  `;
};

export const buttonStyle = css<ButtonStyleProps>`
  gap: 8px;
  align-items: center;
  border-radius: 24px;
  display: inline-flex;
  justify-content: center;
  transition: background-color 300ms ease-out;

  ${({
    theme,
    $grow,
    $bold,
    $width,
    $styleType,
    $chainType,
    $isInteractive,
    $gradientBorder,
    $iconColorParam,
    $nativeIconColor,
  }) => {
    const config = theme.config.button[$styleType];

    return css`
      ${$width &&
      css`
        width: ${$width};
      `}

      ${($gradientBorder || $styleType === 'primaryDark') &&
      css`
        ${gradientBorder};
      `}
      
      ${config.border &&
      css`
        border: ${config.border};
      `}
      
      ${config.icon &&
      css`
        svg path {
          ${$iconColorParam === 'fill'
            ? css`
                fill: ${config.icon};
              `
            : css`
                stroke: ${config.icon};
              `}
        }
      `}

      ${config.background &&
      css`
        padding: 12px 24px;
        background: ${config.background.main};
        box-shadow: ${theme.config.boxShadow};

        ${!theme.responsive.isMobile &&
        css`
          padding: 16px 24px;
        `}

        ${$isInteractive
          ? css`
              &:hover:not(:disabled) {
                background: ${config.background.hover};
              }

              &:active:not(:disabled) {
                background: ${config.background.active};
              }
            `
          : css`
              cursor: initial;
            `}
      `}

      ${$styleType === 'text' &&
      !$nativeIconColor &&
      $isInteractive &&
      css`
        &:hover:not(:disabled) {
          svg path {
            ${$iconColorParam === 'fill'
              ? css`
                  fill: ${theme.config.text.primary.hover};
                `
              : css`
                  stroke: ${theme.config.text.primary.hover};
                `}
          }
        }

        &:active:not(:disabled) {
          svg path {
            ${$iconColorParam === 'fill'
              ? css`
                  fill: ${theme.config.text.primary.active};
                `
              : css`
                  stroke: ${theme.config.text.primary.active};
                `}
          }
        }
      `}

      ${Text} {
        ${getTextColor(theme, $styleType, 'main', $chainType)};
      }

      ${$isInteractive &&
      css`
        &:hover:not(:disabled) {
          ${Text} {
            ${getTextColor(theme, $styleType, 'hover', $chainType)};
          }
        }

        &:active:not(:disabled) {
          ${Text} {
            ${getTextColor(theme, $styleType, 'active', $chainType)};
          }
        }
      `}

      &:disabled {
        opacity: 0.3;
        cursor: initial;
      }

      ${!theme.responsive.isMobile &&
      css`
        border-radius: 28px;
      `}

      ${$grow &&
      css`
        width: 100%;
      `}
      
      ${$bold &&
      css`
        font-weight: 600;
      `}
    `;
  }}
`;

export const Button = styled.button<ButtonStyleProps>`
  ${buttonStyle};
`;

export const Link = styled(RouterLink)<ButtonStyleProps>`
  ${buttonStyle};
`;

export const Loader = styled(Icon.Loader)`
  width: 24px;
  height: 24px;
`;
