import styled from 'styled-components';
import { List } from 'react-virtualized';

import { HeadersRow as HeadersRowComponent } from 'polli-commons-fe/components/table/headers-row';

export const HeadersRow = styled(HeadersRowComponent)``;

export const VirtualizedList = styled(List)`
  padding: 0 16px;
`;

export const TableRowWrapper = styled.div<{ gap: number }>`
  padding-bottom: ${({ gap }) => gap}px;
`;
