import { formatNumber } from 'polli-commons-fe/utils/helpers';

import { DelegatedItemInfo } from 'store/slices/delegation-process-state/types';

export const calculateTotalTokensToDelegate = (
  validators: Record<string, DelegatedItemInfo>
) => {
  const formattedNumber = formatNumber(
    Object.values(validators).reduce(
      (acc, { amount }) => acc + Number(amount),
      0
    )
  );

  return formattedNumber === 0 ? '' : String(formattedNumber);
};

export const distributeAmountAmongValidators = (
  totalAmount: string,
  validatorAddresses: string[]
): Record<string, DelegatedItemInfo> => {
  const numValidators = validatorAddresses.length;
  const amountPerValidator = +totalAmount / numValidators;

  let remainingAmount = +totalAmount;

  return validatorAddresses.reduce(
    (acc, address, index) => {
      let amount;

      if (index === numValidators - 1) {
        amount = remainingAmount;
      } else {
        amount = parseFloat(amountPerValidator.toFixed(6));
        remainingAmount = parseFloat((remainingAmount - amount).toFixed(6));
      }

      acc[address] = { amount: String(amount) };
      return acc;
    },
    {} as Record<string, DelegatedItemInfo>
  );
};
