import { PublicKey, Connection, LAMPORTS_PER_SOL } from '@solana/web3.js';

import { ChainRpcUrls } from 'types/data';

export const lamportsToSol = (lamports: number | string) =>
  String(+lamports / LAMPORTS_PER_SOL);

export const getSolanaAccountBalance = async (pubKey: string) => {
  const connection = new Connection(ChainRpcUrls.SOLANA.main, 'confirmed');
  const wallet = new PublicKey(pubKey);

  const balanceInLamports = await connection.getBalance(wallet);

  return lamportsToSol(balanceInLamports);
};
