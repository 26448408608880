import { useMemo } from 'react';

import { ChainType, CosmosChainType } from 'polli-commons-fe/types';

import { WalletProvider } from 'types';
import { isCosmosChainType } from 'utils/helpers';
import { CosmosWallet } from 'store/api/cosmos-wallets/types';
import {
  useGetSolanaWallets,
  useGetSolanaReadonlyWallets,
} from 'store/api/solana/wallets';
import {
  useGetCosmosWalletsQuery,
  useCosmosReadonlyWallets,
} from 'store/api/cosmos-wallets';
import {
  EthereumWallet,
  useEthReadonlyWallets,
  useGetQuery as useGetEthWalletsQuery,
} from 'store/api/wallets';

import { useConnectedUserWallets } from './use-connected-user-wallets';

export interface ExtensionEthereumWallet extends EthereumWallet {
  provider: WalletProvider;
}

export interface ExtensionCosmosWallet extends CosmosWallet {
  provider: WalletProvider;
}

export function useConnectedWallets(
  chainType: 'ETHEREUM'
): ExtensionEthereumWallet[];
export function useConnectedWallets(
  chainType: CosmosChainType
): ExtensionCosmosWallet[];
export function useConnectedWallets(
  chainType?: ChainType | null
): ExtensionCosmosWallet[] | ExtensionEthereumWallet[];

export function useConnectedWallets(chainType?: ChainType | null) {
  const userWallets = useConnectedUserWallets();
  const { data: ethReadonlyWallets } = useEthReadonlyWallets();
  const { data: cosmosReadonlyWallets } = useCosmosReadonlyWallets();
  const { data: solanaReadonlyWallets } = useGetSolanaReadonlyWallets();

  const { data: ethWalletsData } = useGetEthWalletsQuery();
  const { data: cosmosWalletsData } = useGetCosmosWalletsQuery();
  const { data: solanaWalletsData } = useGetSolanaWallets();

  const filteredUserWallets = chainType
    ? userWallets.filter((item) => item.chainType === chainType)
    : userWallets;

  const aggregatedUserWallets = useMemo(
    () =>
      filteredUserWallets
        .map((item) => {
          if (isCosmosChainType(item.chainType)) {
            const cosmosWallet = cosmosWalletsData?.find(
              ({ wallet }) => wallet.address === item.address
            );

            if (cosmosWallet) {
              return {
                provider: item.provider,
                ...cosmosWallet,
              };
            }
          }

          if (item.chainType === 'ETHEREUM') {
            const ethWallet = ethWalletsData?.find(
              ({ wallet }) => wallet.address === item.address
            );

            if (ethWallet) {
              return {
                provider: item.provider,
                ...ethWallet,
              };
            }
          }

          if (item.chainType === 'SOLANA') {
            const solanaWalletData = solanaWalletsData?.find(
              ({ wallet }) => wallet.address === item.address
            );

            return {
              provider: item.provider,
              ...solanaWalletData,
            };
          }
        })
        .filter(Boolean),
    [cosmosWalletsData, filteredUserWallets, ethWalletsData, solanaWalletsData]
  );

  return useMemo(
    () => [
      ...(aggregatedUserWallets ?? []),
      ...((!chainType || chainType === 'ETHEREUM') && ethReadonlyWallets
        ? ethReadonlyWallets
        : []),
      ...((!chainType || isCosmosChainType(chainType)) && cosmosReadonlyWallets
        ? cosmosReadonlyWallets.filter((item) =>
            chainType ? item.wallet.chainType === chainType : true
          )
        : []),
      ...((!chainType || chainType === 'SOLANA') && solanaReadonlyWallets
        ? solanaReadonlyWallets
        : []),
    ],
    [
      chainType,
      ethReadonlyWallets,
      aggregatedUserWallets,
      cosmosReadonlyWallets,
      solanaReadonlyWallets,
    ]
  );
}
