import { useMemo, useCallback } from 'react';

import {
  ChainId,
  ChainType,
  CosmosWalletProvider,
} from 'polli-commons-fe/types';

import { isCosmosChainType } from 'utils/helpers';
import { useAppDispatch, useAppSelector } from 'store';
import {
  connectCosmosChains,
  ConnectedCosmosChainsState,
  selectConnectedCosmosChains,
} from 'store/slices/connected-cosmos-chains';

interface UseKeplrProviderReturnType
  extends Pick<ConnectedCosmosChainsState, 'chains' | 'providerName'> {
  isConnectedToChains: boolean;
  connect: (provider: CosmosWalletProvider) => void;
}

export function useCosmosChainProvider(
  chainType?: undefined
): UseKeplrProviderReturnType;
export function useCosmosChainProvider(
  chainType: ChainType | undefined
): UseKeplrProviderReturnType['chains'][ChainId];

export function useCosmosChainProvider(chainType?: ChainType | undefined) {
  const dispatch = useAppDispatch();

  const connectedChainsState = useAppSelector(selectConnectedCosmosChains);

  const connect = useCallback(
    (provider: CosmosWalletProvider) => dispatch(connectCosmosChains(provider)),
    [dispatch]
  );

  const state: UseKeplrProviderReturnType = useMemo(() => {
    return {
      connect,
      chains: connectedChainsState.chains,
      providerName: connectedChainsState.providerName,
      isConnectedToChains: Object.values(connectedChainsState.chains).some(
        ({ address }) => Boolean(address)
      ),
    };
  }, [connectedChainsState, connect]);

  if (chainType && isCosmosChainType(chainType))
    return state.chains[ChainId[chainType]];

  return state;
}
