import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const selectAuth = (store: RootState) => store.auth;

const selectAuthUser = createSelector([selectAuth], (store) => store.authUser);

const selectIsLoggedIn = createSelector(
  [selectAuth],
  (store) => store.isLoggedIn
);

const selectAuthRedirectUrl = createSelector(
  [selectAuth],
  (store) => store.redirectUrl
);

export { selectAuth, selectAuthUser, selectIsLoggedIn, selectAuthRedirectUrl };
