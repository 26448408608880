import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';

import { showError } from '../utils/helpers';

export const rtkQueryErrorHandler =
  ({ ignore }: { ignore?: string[] }): Middleware =>
  () =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      if (ignore?.some((path) => action.type.includes(path))) {
        return next(action);
      }

      const payload = action.payload as any;

      const path = payload.data?.path;
      const message = payload.message;
      const errorMessage = payload.data?.errorMessage;
      const error = payload.data?.error;
      const url = payload.url;

      const resultErrorMessage = import.meta.env.DEV ? (
        <>
          {typeof payload === 'string' ? (
            payload
          ) : (
            <>
              <p>URL: {url}</p>
              <p>{message}</p>
              <p>{errorMessage}</p>
            </>
          )}
        </>
      ) : (
        'Something went wrong'
      );

      const toastId =
        typeof action.payload === 'string'
          ? action.payload
          : typeof path === 'string'
            ? path
            : typeof message === 'string'
              ? message
              : typeof errorMessage === 'string'
                ? errorMessage
                : typeof error === 'string'
                  ? error
                  : 'Error';

      showError(resultErrorMessage, {
        toastId,
      });
    }

    return next(action);
  };
