import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

export interface RedelegateDialogFormValues {
  amount: string;
  sourceAddress: string | undefined;
}

export type RedelegateDialogType = 'provider' | 'validator';

export interface RedelegateDialogState {
  type?: RedelegateDialogType | null;
  walletAddress: string | null | undefined;
  initialValues?: Partial<RedelegateDialogFormValues>;
}

const initialState: RedelegateDialogState = {
  type: null,
  walletAddress: null,
};

export const redelegateDialogSlice = createSlice({
  initialState,
  name: 'redelegateDialog',
  reducers: {
    closeRedelegateDialog: () => initialState,
    openRedelegateDialog: (
      _,
      { payload }: PayloadAction<RedelegateDialogState>
    ) => payload,
  },
});

export const {
  actions: { openRedelegateDialog, closeRedelegateDialog },
} = redelegateDialogSlice;

export const selectRedelegateDialogState = (store: RootState) =>
  store.redelegateDialog;
