import { ReactNode } from 'react';

import {
  ChainTypeIcon,
  CurrencyToChainType,
} from 'polli-commons-fe/types/data';
import {
  ChainType,
  AmountResponse,
  CosmosChainType,
} from 'polli-commons-fe/types';

import { Icon } from 'components/icons';

import {
  Preset,
  WalletProvider,
  WalkthroughStep,
  WalkthroughStepOptions,
} from '.';

export const PresetOptionsTitles: Record<Preset, string> = {
  '25': '25%',
  '50': '50%',
  '75': '75%',
  '100': 'Max',
};

export const ChainTypeTitle: Record<ChainType, string> = {
  LAVA: 'Lava',
  SECRET: 'Secret',
  COSMOS: 'Cosmos',
  SOLANA: 'Solana',
  ETHEREUM: 'Ethereum',
};

interface WalletProviderConfigBaseProps {
  title: string;
  icon: ReactNode;
}

export const WalletProviderConfig: Record<
  WalletProvider,
  WalletProviderConfigBaseProps
> = {
  leap: {
    title: 'Leap',
    icon: <Icon.Leap />,
  },
  keplr: {
    title: 'Keplr',
    icon: <Icon.Keplr />,
  },
  trezor: {
    title: 'Trezor',
    icon: <Icon.Trezor />,
  },
  solana: {
    title: 'Phantom',
    icon: <Icon.Phantom />,
  },
  cosmostation: {
    title: 'Cosmostation',
    icon: <Icon.Cosmostation />,
  },
  walletConnect: {
    title: 'Wallet Connect',
    icon: <Icon.WalletConnect />,
  },
  phantom: {
    title: 'Phantom',
    icon: <Icon.Phantom width={24} height={24} />,
  },
  metamask: {
    title: 'Metamask',
    icon: <Icon.Metamask width={24} height={24} />,
  },
};

export const ConflictingWalletProviders: Record<
  WalletProvider,
  WalletProvider[]
> = {
  // TODO
  phantom: [],
  keplr: ['cosmostation', 'leap'],
  cosmostation: ['leap', 'keplr'],
  leap: ['keplr', 'cosmostation'],
  trezor: ['walletConnect', 'metamask'],
  walletConnect: ['trezor', 'metamask'],
  metamask: ['trezor', 'walletConnect'],
};

export const WalkthroughDataAttributeName = 'data-walkthrough-step' as const;

export const WalkthroughDataAttribute = Object.fromEntries(
  WalkthroughStepOptions.map((option) => [
    option,
    { [WalkthroughDataAttributeName]: option },
  ])
) as Record<
  WalkthroughStep,
  Record<typeof WalkthroughDataAttributeName, WalkthroughStep>
>;

export const ChainRestUrl: Record<CosmosChainType, string> = {
  LAVA: '/api-lava-rest',
  COSMOS: '/api-cosmos-rest',
  SECRET: '/api-secret-rest',
};

export const ChainRpcUrls: Record<
  Exclude<ChainType, 'ETHEREUM'>,
  { main: string; alternative: string }
> = {
  LAVA: {
    main: '/api-lava-rpc',
    alternative: '/api-lava-rpc',
  },
  COSMOS: {
    main: '/api-cosmos-rpc',
    alternative: '/api-cosmos-rpc',
  },
  SECRET: {
    main: '/api-secret-rpc',
    alternative: '/api-secret-rpc',
  },
  SOLANA: {
    main: `https://api.devnet.solana.com`,
    alternative: `https://api.devnet.solana.com`,
  },
};

export const BaseCurrencyIcon: Record<string, ReactNode> = Object.entries(
  CurrencyToChainType
).reduce<Record<string, ReactNode>>((result, [baseCurrency, mainCurrency]) => {
  result[baseCurrency] = ChainTypeIcon[mainCurrency];
  return result;
}, {});

export const ChainToBaseCurrency: Record<
  ChainType,
  AmountResponse['currency']
> = {
  LAVA: 'ulava',
  ETHEREUM: 'wei',
  SECRET: 'uscrt',
  COSMOS: 'uatom',
  SOLANA: 'lamport',
};
