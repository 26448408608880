import * as Sentry from '@sentry/react';
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import { getErrorMessage } from 'polli-commons-fe/utils/error';
import { ChainId, CosmosWalletProvider } from 'polli-commons-fe/types';
import { getCosmosWalletAddress } from 'polli-commons-fe/utils/cosmos';

import { RootState } from 'store/store';
import { isWalletAlreadyLinkedError } from 'utils/helpers';
import { cosmosWalletsApi } from 'store/api/cosmos-wallets';
import { ENABLED_COSMOS_NETWORK_CHAIN_TYPES } from 'config/constants';
import { setAlreadyLinkedWallets } from 'store/slices/already-linked-wallets-addresses';

export interface ConnectedCosmosChainState {
  address: string | null;
}

export type ConnectedCosmosChainsState = {
  providerName: CosmosWalletProvider | null;
  chains: {
    [key in ChainId]: ConnectedCosmosChainState;
  };
};

export const connectedCosmosChainsInitialState: ConnectedCosmosChainsState = {
  providerName: null,
  chains: Object.values(ChainId).reduce<ConnectedCosmosChainsState['chains']>(
    (result, chainId) => {
      result[chainId] = {
        address: null,
      };

      return result;
    },
    {} as ConnectedCosmosChainsState['chains']
  ),
};

export const connectCosmosChains = createAsyncThunk(
  'connectedCosmosChains/connectCosmosChain',
  async (providerName: CosmosWalletProvider, { dispatch, rejectWithValue }) => {
    try {
      const chains: Partial<ConnectedCosmosChainsState['chains']> = {};

      const savedCosmosWalletsPromise = dispatch(
        cosmosWalletsApi.endpoints.getCosmosWallets.initiate()
      );

      const savedCosmosWallets = await savedCosmosWalletsPromise.unwrap();

      if (savedCosmosWallets) {
        for (const chain of ENABLED_COSMOS_NETWORK_CHAIN_TYPES) {
          const chainId = ChainId[chain];

          const walletAddress = await getCosmosWalletAddress({
            providerName,
            chainType: chain,
          });

          if (!walletAddress) {
            Sentry.captureMessage(
              `Failed to fetch wallet address for chain: ${chain}`,
              {
                level: 'warning',
                extra: { chain, providerName },
              }
            );

            continue;
          }

          const savedWalletsIncludesAddress = savedCosmosWallets?.some(
            ({ wallet }) => wallet.address === walletAddress
          );

          if (!savedWalletsIncludesAddress) {
            const saveCosmosWalletPromise = dispatch(
              cosmosWalletsApi.endpoints.saveCosmosWallet.initiate(
                walletAddress
              )
            );

            const { error: saveWalletError } = await saveCosmosWalletPromise;

            if (saveWalletError) {
              if (isWalletAlreadyLinkedError(saveWalletError)) {
                dispatch(setAlreadyLinkedWallets([walletAddress]));
              }

              return rejectWithValue(getErrorMessage(saveWalletError));
            }
          }

          chains[chainId] = {
            address: walletAddress,
          };
        }

        return {
          chains,
          providerName,
        } as ConnectedCosmosChainsState;
      }
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

export const connectedCosmosChainsSlice = createSlice({
  name: 'connectedCosmosChains',
  initialState: connectedCosmosChainsInitialState,
  reducers: {
    disconnectAllCosmosChains: () => connectedCosmosChainsInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      connectCosmosChains.rejected,
      () => connectedCosmosChainsInitialState
    );
    builder.addCase(
      connectCosmosChains.fulfilled,
      (_, action) => action.payload
    );
  },
});

export const { disconnectAllCosmosChains } = connectedCosmosChainsSlice.actions;

const selectConnectedCosmosChainsState = (state: RootState) =>
  state.connectedCosmosChains;

export const selectConnectedCosmosChains = createSelector(
  [selectConnectedCosmosChainsState],
  (state) => ({
    ...state,
    chains: ENABLED_COSMOS_NETWORK_CHAIN_TYPES.reduce(
      (result, chainType) => {
        const chainId = ChainId[chainType];

        result[chainId] = state.chains[chainId];

        return result;
      },
      {} as ConnectedCosmosChainsState['chains']
    ),
  })
);

export const selectConnectedCosmosProviderName = createSelector(
  [selectConnectedCosmosChainsState],
  (state) => state.providerName
);
