import React from 'react';
import dayjs from 'dayjs';
import { App } from 'app';
import ReactGA from 'react-ga4';
import utc from 'dayjs/plugin/utc';
import '@fontsource-variable/inter';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { useTheme } from 'styled-components';
import duration from 'dayjs/plugin/duration';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import TrezorConnect from '@trezor/connect-web';
import { BrowserRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import relativeTime from 'dayjs/plugin/relativeTime';
import { fetchAuthSession } from '@aws-amplify/auth';
import axios, { InternalAxiosRequestConfig } from 'axios';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { PersistGate } from 'redux-persist/integration/react';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { defaultConfig, createWeb3Modal } from '@web3modal/ethers/react';
import {
  WalletProvider as SolanaWalletProvider,
  ConnectionProvider as SolanaWalletConnectionAdapter,
} from '@solana/wallet-adapter-react';

import { initSentry } from 'polli-commons-fe/sentry';
import { Icon, Button } from 'polli-commons-fe/components';
import { handleError } from 'polli-commons-fe/utils/error';
import { apiInstances as commonApiInstances } from 'polli-commons-fe/api/instance';
import {
  ThemeWrap,
  ThemeContextProvider,
} from 'polli-commons-fe/context/theme';

import { URL } from 'api/constants';
import { Web3WalletId } from 'types';
import { store, persistor } from 'store';
import { ChainRpcUrls } from 'types/data';
import { clearAuth } from 'store/slices/auth';
import { SOLANA_ENABLED } from 'config/constants';
import { solanaApiInstance, compoundingApiInstance } from 'api/instance';
import {
  saveWalletSignInTokens,
  getWalletSignInAccessToken,
  getWalletSignInRefreshToken,
} from 'utils/wallet-sign-in';

import { GlobalStyles } from './styles/globalStyles';
import { HeaderContextProvider } from './context/header';

window.global ||= window;

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(duration);

initSentry();

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_REACT_APP_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_REACT_APP_USER_POOL_APP_CLIENT_ID,
    },
  },
});

const Toast = () => {
  const theme = useTheme();

  return (
    <ToastContainer
      theme={theme.isDarkTheme ? 'dark' : 'light'}
      closeButton={({ closeToast }) => (
        <Button
          styleType="text"
          onClick={closeToast}
          icon={<Icon.Close />}
          style={{ alignSelf: 'flex-start' }}
        />
      )}
    />
  );
};

const mainnet = {
  chainId: 1,
  currency: 'ETH',
  name: 'Ethereum',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
};

const metadata = {
  icons: [],
  name: 'Polli.co',
  url: 'https://dev.polli.co',
  description: 'Multi-Chain Staking Dashboard',
};

createWeb3Modal({
  chains: [mainnet],
  ethersConfig: defaultConfig({ metadata }),
  projectId: import.meta.env.VITE_REACT_APP_WALLET_CONNECT_PROJECT_ID,
  includeWalletIds: [
    Web3WalletId.Metamask,
    Web3WalletId.Trust,
    Web3WalletId.Exodus,
    Web3WalletId.LedgerLive,
  ],
});

TrezorConnect.init({
  lazyLoad: true,
  manifest: {
    email: 'developer@xyz.com',
    appUrl: 'https://dev.polli.co',
  },
});

ReactGA.initialize(import.meta.env.VITE_REACT_APP_ANALYTICS_TRACKING_ID);

const solanaProviderWallets = [new PhantomWalletAdapter()];

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <SolanaWalletConnectionAdapter endpoint={ChainRpcUrls.SOLANA.main}>
      <SolanaWalletProvider
        autoConnect={SOLANA_ENABLED}
        wallets={solanaProviderWallets}
      >
        <Provider store={store}>
          <BrowserRouter>
            <ThemeContextProvider>
              <ThemeWrap>
                <GlobalStyles />
                <HeaderContextProvider>
                  <PersistGate loading={null} persistor={persistor}>
                    <App />
                  </PersistGate>
                </HeaderContextProvider>
                <Toast />
              </ThemeWrap>
            </ThemeContextProvider>
          </BrowserRouter>
        </Provider>
      </SolanaWalletProvider>
    </SolanaWalletConnectionAdapter>
  </React.StrictMode>
);

const refreshWalletSignInAccessToken = async () => {
  const walletRefreshToken = getWalletSignInRefreshToken();

  if (walletRefreshToken) {
    try {
      const response = await axios.post<{ newAccessToken: string }>(
        URL.REFRESH_TOKEN,
        {
          refreshToken: walletRefreshToken,
        }
      );

      const { newAccessToken } = response.data;

      saveWalletSignInTokens({
        accessToken: newAccessToken,
      });

      return newAccessToken;
    } catch (error) {
      handleError(error);
      store.dispatch(clearAuth());
      return null;
    }
  }

  return null;
};

const handleWalletSignInUnauthorized = async (error: any) => {
  if (error.response && error.response.status === 401) {
    const originalRequest = error.config;

    const newAccessToken = await refreshWalletSignInAccessToken();
    if (newAccessToken) {
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      return axios(originalRequest);
    }
  }

  return axios(error.config);
};

const setToken = async (axiosConfig: InternalAxiosRequestConfig) => {
  let walletSignInAccessToken = getWalletSignInAccessToken();

  const authSession = await fetchAuthSession();
  const emailUserAccessToken = authSession?.tokens?.accessToken ?? null;

  const config = { ...axiosConfig };

  if (!walletSignInAccessToken && !emailUserAccessToken) {
    walletSignInAccessToken = await refreshWalletSignInAccessToken();
  }

  if (walletSignInAccessToken) {
    config.headers.Authorization = `Bearer ${walletSignInAccessToken}`;
  } else if (emailUserAccessToken) {
    config.headers.Authorization = `Bearer ${emailUserAccessToken}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
};

[...commonApiInstances, compoundingApiInstance, solanaApiInstance].forEach(
  (instance) => {
    instance.interceptors.request.use(setToken);
    instance.interceptors.response.use(
      (response) => response,
      handleWalletSignInUnauthorized
    );
  }
);
