import { Semibold } from 'polli-commons-fe/components';
import { TableWithSortingProps } from 'polli-commons-fe/components/sortable-table';
import { composeGridTemplateColumns } from 'polli-commons-fe/components/table/helpers';
import {
  formatNumber,
  addPercentageLabel,
} from 'polli-commons-fe/utils/helpers';

import { CosmosValidatorInfo } from 'components';
import { formatTotalStakedTokens } from 'utils/currencies';
import { CosmosValidatorData } from 'store/api/cosmos-validators';
import { VirtualizedListProps } from 'components/virtualized-list/types';

import { BaseTable } from './base';
import { CosmosValidatorsTableProps } from './types';

const validatorsListTemplate = composeGridTemplateColumns([
  { fr: 2 },
  { fr: 1, repeat: 3 },
  { fr: 2 },
  { fr: 1, repeat: 2 },
]);

const headers: TableWithSortingProps['headers'] = [
  {
    accessor: 'name',
    label: 'Validator',
  },
  {
    label: 'Validator APR',
    accessor: 'validatorAnnualPercentageRate',
  },
  {
    label: 'Network APR',
    accessor: 'networkAnnualPercentageRate',
  },
  {
    label: 'Validator Commission',
    accessor: 'validatorCommissionRate',
  },
  {
    accessor: 'totalStakedTokens',
    label: 'Total Delegated Tokens',
  },
  {
    label: 'Voting Power',
    accessor: 'votingPower',
    info: 'Represents the influence a participant has in the network decision-making process, determined by the amount of cryptocurrency they have staked with validators',
  },
  {
    label: 'Scoring rate',
    accessor: 'scoringRate',
    info: 'Custom indicator that combines factors such as APR, commission rates, and overall voting power to assess their reliability and performance within the network',
  },
];

export const CosmosValidatorsTable = ({
  chainType,
  renderTableRow,
  additionalHeaders,
  additionalTemplate,
  ...props
}: CosmosValidatorsTableProps) => {
  const resultHeaders = [...headers, ...(additionalHeaders ?? [])];
  const resultTemplate = additionalTemplate
    ? `${validatorsListTemplate} ${additionalTemplate}`
    : validatorsListTemplate;

  const rowRenderer: VirtualizedListProps<CosmosValidatorData>['rowRenderer'] =
    (currentItemData) => {
      const baseRowProps = {
        template: resultTemplate,
        values: [
          <CosmosValidatorInfo
            name={currentItemData.name}
            address={currentItemData.address}
          />,
          <Semibold>
            {addPercentageLabel(
              formatNumber(currentItemData.validatorAnnualPercentageRate, 2)
            )}
          </Semibold>,
          addPercentageLabel(
            formatNumber(currentItemData.networkAnnualPercentageRate, 2)
          ),
          addPercentageLabel(currentItemData.validatorCommissionRate),
          formatTotalStakedTokens(currentItemData.totalStakedTokens, chainType),
          addPercentageLabel(formatNumber(currentItemData.votingPower, 2)),
          formatNumber(currentItemData.scoringRate, 2),
        ],
      };

      return renderTableRow({ baseRowProps, data: currentItemData });
    };

  return (
    <BaseTable
      headers={resultHeaders}
      rowRenderer={rowRenderer}
      template={resultTemplate}
      {...props}
    />
  );
};
