import { ReactNode } from 'react';

import { FlexProps } from 'polli-commons-fe/components/styles';
import { Flex, Icon, EllipsisText } from 'polli-commons-fe/components';

export const ValidatorInfo = ({
  name,
  icon = <Icon.Validator />,
  ...props
}: {
  name?: string;
  icon?: ReactNode;
} & FlexProps) => {
  return (
    <Flex gap={8} minWidth={0} {...props}>
      {icon}

      <EllipsisText>{name}</EllipsisText>
    </Flex>
  );
};
