import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useWallet as useSolanaAdapterWallet } from '@solana/wallet-adapter-react';

import { useAppSelector } from 'store';
import { useCosmosChainProvider } from 'hooks';
import { selectTrezor } from 'store/slices/trezor';

import { useMetamaskProvider } from './use-metamask-provider';

export const useWalletProviders = () => {
  const cosmos = useCosmosChainProvider();
  const walletConnect = useWeb3ModalAccount();
  const trezor = useAppSelector(selectTrezor);
  const metamask = useMetamaskProvider();
  const phantom = useSolanaAdapterWallet();

  return { trezor, cosmos, phantom, metamask, walletConnect };
};
