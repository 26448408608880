import axios from 'axios';
import { decodeJWT } from '@aws-amplify/auth';

import { CosmosChainType } from 'polli-commons-fe/types';

import { URL } from 'api/constants';
import { saveAuthTypeToLocalStorage } from 'store/slices/auth/helpers';

interface SignatureMessageResponse {
  messageToSign: string;
  walletAddress: string;
}

interface SignatureVerifyResponse {
  accessToken: string;
  refreshToken: string;
  walletAddress: string;
  chainType: CosmosChainType;
}

export const walletSignInAccessTokenKey = 'walletSignInAccessToken';
const walletSignInRefreshTokenKey = 'walletSignInRefreshToken';

export const saveWalletSignInTokens = (tokens: {
  accessToken: string;
  refreshToken?: string;
}) => {
  localStorage.setItem(walletSignInAccessTokenKey, tokens.accessToken);

  if (tokens.refreshToken) {
    localStorage.setItem(walletSignInRefreshTokenKey, tokens.refreshToken);
  }
};

export const clearWalletSignInTokens = () => {
  localStorage.removeItem(walletSignInAccessTokenKey);
  localStorage.removeItem(walletSignInRefreshTokenKey);
};

export const getWalletSignInAccessToken = () =>
  localStorage.getItem(walletSignInAccessTokenKey);

export const getWalletSignInRefreshToken = () =>
  localStorage.getItem(walletSignInRefreshTokenKey);

export const generateAndSaveWalletSignInTokens = async (params: {
  walletAddress: string;
  publicKey: string | null;
  onMessageSign: (messageToSign: string) => Promise<string | undefined>;
}) => {
  const { publicKey, walletAddress, onMessageSign } = params;

  const generatedMessageResponse = await axios.post<SignatureMessageResponse>(
    URL.GENERATE_SIGNATURE_MESSAGE,
    {
      walletAddress,
    }
  );

  const { messageToSign } = generatedMessageResponse.data;

  if (walletAddress) {
    const signature = await onMessageSign(messageToSign);

    if (signature) {
      const verifyResult = await axios.post<SignatureVerifyResponse>(
        URL.VERIFY_CHALLENGE,
        {
          publicKey,
          signature,
          walletAddress,
        }
      );

      const { accessToken, refreshToken } = verifyResult.data;
      saveWalletSignInTokens({ accessToken, refreshToken });

      const decoded = decodeJWT(accessToken);

      saveAuthTypeToLocalStorage('wallet');

      return decoded.payload.sub;
    }
  }
};
