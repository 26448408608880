import { mapQuery } from 'polli-commons-fe/utils/query';
import { useAnalyticsEventTracker } from 'polli-commons-fe/hooks';

import { VirtualizedList } from 'components';

import { BaseTableProps } from './types';

export const BaseTable = <T,>({
  data,
  headers,
  template,
  sortState,
  emptyTitle,
  rowRenderer,
  handleSorting,
  sortingDisabled,
  additionalTemplate,
  placeholderRowsCount = 9,
  ...validatorsQuery
}: BaseTableProps<T>) => {
  const eventTracker = useAnalyticsEventTracker(
    'Choose validators to delegate'
  );

  return (
    <VirtualizedList
      {...mapQuery(validatorsQuery)}
      data={data}
      headers={headers}
      template={template}
      sortState={sortState}
      emptyTitle={emptyTitle}
      rowRenderer={rowRenderer}
      eventTracker={eventTracker}
      handleSorting={handleSorting}
      sortingDisabled={sortingDisabled}
      placeholderRowsCount={placeholderRowsCount}
    />
  );
};
