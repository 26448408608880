import { ElementType, HTMLAttributes } from 'react';

import { ChainType } from 'polli-commons-fe/types';

import * as Styles from './styles';

export interface GradientHeaderProps
  extends HTMLAttributes<HTMLHeadingElement> {
  as?: ElementType;
  chainType?: ChainType;
}

export const GradientHeader = ({
  children,
  chainType,
  ...props
}: GradientHeaderProps) => (
  <Styles.Header {...props}>
    <Styles.Text chainType={chainType}>{children}</Styles.Text>
  </Styles.Header>
);
