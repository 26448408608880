import { createApi } from '@reduxjs/toolkit/query/react';

import { SortRequest, PaginationRequest } from 'polli-commons-fe/types';
import {
  getSortParams,
  getPageParams,
  addParamsToUrl,
} from 'polli-commons-fe/utils/query';

import { URL } from 'api/constants';
import { solanaApiQuery } from 'store/query';

import { SolanaValidatorsResponse } from './types';

export const solanaValidatorsApi = createApi({
  baseQuery: solanaApiQuery,
  reducerPath: 'solanaValidatorsApi',
  endpoints: (build) => ({
    getSolanaValidators: build.query<
      SolanaValidatorsResponse,
      void | (Partial<PaginationRequest> & SortRequest)
    >({
      query: (params) => {
        const { sort, page = 0, size = 1000 } = params || {};

        return {
          method: 'get',
          url: addParamsToUrl(URL.VALIDATORS, {
            size,
            ...params,
            ...getPageParams(page),
            ...getSortParams(sort),
          }),
        };
      },
    }),
  }),
});

export const { useGetSolanaValidatorsQuery } = solanaValidatorsApi;
