import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'polli-commons-fe/store/query';

import { URL } from 'api/constants';

import {
  UnstakeDetails,
  UnDelegationData,
  GetCosmosDelegationsWalletResponse,
} from './types';

export const cosmosDelegationsTagTypes = ['cosmos-delegations'];

export const cosmosDelegationsApi = createApi({
  baseQuery: cosmosApiQuery,
  tagTypes: cosmosDelegationsTagTypes,
  reducerPath: 'cosmosDelegationsApi',
  endpoints: (build) => ({
    getCosmosDelegationsWallet: build.query<
      GetCosmosDelegationsWalletResponse,
      string
    >({
      providesTags: cosmosDelegationsTagTypes,
      query: (walletAddress) => ({
        method: 'get',
        url: URL.DELEGATIONS.replace('{walletAddress}', walletAddress),
      }),
    }),
  }),
});

export const { useGetCosmosDelegationsWalletQuery } = cosmosDelegationsApi;

export type {
  UnstakeDetails,
  UnDelegationData,
  GetCosmosDelegationsWalletResponse,
};
