import axios from 'axios';

const COMPOUNDING_BASE_URL = '/api-compounding';

export const compoundingApiInstance = axios.create({
  baseURL: COMPOUNDING_BASE_URL,
});

export const solanaApiInstance = axios.create({
  baseURL: '/api-solana',
});
