import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { solanaApiQuery } from 'store/query';
import { SOLANA_ENABLED } from 'config/constants';

import { SolanaWalletData } from './types';

export const solanaWalletsTagTypes = ['solana-wallets'];

export const solanaWalletsApi = createApi({
  baseQuery: solanaApiQuery,
  tagTypes: solanaWalletsTagTypes,
  reducerPath: 'solanaWalletsApi',
  endpoints: (build) => ({
    saveSolanaWallet: build.mutation<void, string>({
      invalidatesTags: solanaWalletsTagTypes,
      query: (address) => ({
        method: 'post',
        url: URL.WALLETS,
        data: { address },
      }),
    }),
    saveSolanaReadOnlyWallet: build.mutation<void, string>({
      invalidatesTags: solanaWalletsTagTypes,
      query: (address) => ({
        method: 'post',
        url: URL.READONLY_WALLETS,
        data: {
          address,
        },
      }),
    }),
    deleteSolanaReadOnlyWallet: build.mutation<
      void,
      SolanaWalletData['wallet']['id']
    >({
      invalidatesTags: solanaWalletsTagTypes,
      query: (walletId) => ({
        method: 'delete',
        url: URL.READONLY_WALLET.replace('{walletId}', String(walletId)),
      }),
    }),
    getSolanaWallets: build.query<SolanaWalletData[], void>({
      providesTags: solanaWalletsTagTypes,
      query: () => ({
        method: 'get',
        url: URL.WALLETS,
      }),
      transformResponse: (response: SolanaWalletData[]) =>
        response.map((item) => ({
          ...item,
          wallet: {
            ...item.wallet,
            chainType: 'SOLANA',
          },
        })),
    }),
  }),
});

export const {
  useSaveSolanaWalletMutation,
  useSaveSolanaReadOnlyWalletMutation,
  useDeleteSolanaReadOnlyWalletMutation,
} = solanaWalletsApi;

const useSkipToken = () => {
  return !SOLANA_ENABLED;
};

export const useGetSolanaWallets = () => {
  const skipToken = useSkipToken();

  return solanaWalletsApi.useGetSolanaWalletsQuery(undefined, {
    skip: skipToken,
  });
};

export const useGetSolanaReadonlyWallets = () => {
  const skipToken = useSkipToken();

  return solanaWalletsApi.useGetSolanaWalletsQuery(undefined, {
    skip: skipToken,
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data: data?.filter(({ wallet }) => wallet.isReadOnly),
    }),
  });
};
